import React from "react"
import HeaderContact from "./headercontact"
import FooterContact from "./FooterContact"
import "../css/layout.css"

const LayoutContact = ({ children }) => {
  return (
    <main>
      <HeaderContact />
      {children}
      <FooterContact />
    </main>
  )
}
// joey
export default LayoutContact
