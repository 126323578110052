import React from "react"
import LayoutContact from "../components/LayoutContact"
import StyledHero from "../components/StyledHero"
import { graphql } from "gatsby"
import Contact from "../components/Contact/Contact"
import SEO from "../components/SEO"

export default function contact({ data }) {
  return (
    <LayoutContact>
      <SEO title="Contact" />
      {/* <StyledHero img={data.contactBcg.childImageSharp.fluid} /> */}
      <Contact />
    </LayoutContact>
  )
}

export const query = graphql`
  query {
    contactBcg: file(relativePath: { eq: "serenity-hero1.png" }) {
      childImageSharp {
        fluid(quality: 60, maxWidth: 1160) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
